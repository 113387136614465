import { template as template_88d1c9db67cf44d381ea910f444e2832 } from "@ember/template-compiler";
const WelcomeHeader = template_88d1c9db67cf44d381ea910f444e2832(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
