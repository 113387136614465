import { template as template_c93491c5fab14d35a49e00f8cc7ca1dc } from "@ember/template-compiler";
const FKControlMenuContainer = template_c93491c5fab14d35a49e00f8cc7ca1dc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
