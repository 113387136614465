import { template as template_5a07a4e03eb044e0a23b76a4555db8de } from "@ember/template-compiler";
const SidebarSectionMessage = template_5a07a4e03eb044e0a23b76a4555db8de(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
